<template>
  <div>
    <v-slide-x-transition group>
      <v-timeline-item
        v-for="(event, i) in events"
        :key="i"
        class="mb-4"
        color="accent"
        small
      >
        <v-row
          style="border-bottom: 1px solid #dfdfdf"
          v-if="!event.deleted || isBackOfficeAdmin"
          justify="space-between"
        >
          <v-col cols="7">
            <div class="mb-2">
              <span>{{ displayDate(event) | getOnlyDate }}</span>
              <span>
                {{ displayDate(event) | getOnlyTime }}
                <small>(GMT+{{ getGmtDiff }})</small></span>
            </div>
            <v-chip class="ml-0 mr-2" label small>
              {{ displayType(event).replace(/_/g, " ") }}
            </v-chip>
            {{ event.user != null ? event.user.name : "N/A" }}
          </v-col>
          <v-col v-if="isBackOfficeAdmin && inquiryId" class="text-right" cols="5">
            <v-btn @click="editItem = { ...event }" icon>
              <v-icon color="third">mdi-pencil</v-icon>
            </v-btn>
            <v-btn @click="deleteInquiry(event.id)" icon>
              <v-icon color="primary">mdi-delete</v-icon>
            </v-btn>
          </v-col>

          <v-col v-if="Number.isInteger(event.refundAmount) || event.referencedBookingNumber" cols="12"
            class="pt-0 text--primary">
            <b>Amount of Refund: {{ event.refundAmount }}</b>

            <h5
              v-if="event.referencedBookingNumber"
              class="grey--text text--darken-4 minw100"
            >
              <v-tooltip
                top
                :open-delay="10"
                :close-delay="5"
              >
                <template v-slot:activator="{on, attrs}">
                  Referenced Booking Number:
                  <v-icon
                    color="mango-red"
                    @click="copyToClipboard(event.referencedBookingNumber)"
                    small
                    v-on="on"
                    v-bind="attrs"
                  >
                    mdi-content-copy
                  </v-icon>
                </template>
                <span>Copy</span>
              </v-tooltip>
              <span class="ml-1 font-weight-bold">{{
                event.referencedBookingNumber
              }}</span>
            </h5>
          </v-col>
          <v-col v-if="event.message || event.description" v-html="displayDescription(event).split('\n').join('<br>')"
            cols="12" class="pt-0 text--primary">
          </v-col>
        </v-row>
      </v-timeline-item>
    </v-slide-x-transition>
    <v-dialog @click:outside="editItem = null" v-if="editItem" v-model="editItem" max-width="700px" persistent>
      <v-card>
        <v-toolbar fixed color="titlebg" class="title" dark>
          <v-toolbar-title>Edit Event</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn class="align-right" icon dark @click="editItem = null">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <div class="content">
          <template>
            <v-container>
              <ValidationObserver ref="upEventObserver" v-slot="{ Validate, reset }">
                <select v-if="eventStatus" id="select" :class="{ 'my-select-dark': $vuetify.theme.dark }"
                  class="pl-2 py-1 mb-5 my-select" v-model="editItem.code" oulined>
                  <optgroup v-for="(item, i) in eventStatus" :label="item.text" :key="i">
                    <option v-for="(opt, index) in item.children" :key="index" :value="opt.code">
                      {{ opt.text }}
                    </option>
                  </optgroup>
                </select>
                <ValidationProvider v-if="editItem.code.startsWith('refund')" v-slot="{ errors }" name="refundAmount"
                  rules="required|numeric">
                  <v-text-field :error-messages="errors" v-model.number="editItem.refundAmount" label="Amount of refund"
                    filled type="number">
                  </v-text-field>
                </ValidationProvider>

                <v-text-field v-if="editItem &&
                  editItem.code &&
                  editItem.code.startsWith('refund')
                  " :error-messages="errors" v-model="editItem.referencedBookingNumber"
                  label="Referenced Booking Number (special credit)" filled type="string">
                </v-text-field>
                <v-textarea v-model="editItem.message" label="Message" outlined>
                </v-textarea>

                <div class="d-flex justify-end">
                  <v-btn :disabled="loading" :loading="loading" @click="updateEvent" color="accent">
                    Save
                  </v-btn>
                </div>
              </ValidationObserver>
            </v-container>
          </template>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import common from "@/mixins/common";
import { copyToClipboard } from "@/util/helpers";
import Trainplanet from "@/util/trainplanet.api";
import moment from "moment";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapGetters } from "vuex";

export default {
  name: "EventList",
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    events: {
      required: true,
      type: Array,
    },
    eventStatus: {
      required: false,
      type: Array,
    },
    inquiryId: {
      required: false,
      type: String,
    },
    getInquiry: {
      required: false,
    },
  },
  mixins: [common],
  data: () => ({
    editItem: null,
  }),
  computed: {
    ...mapGetters({
      isBackOfficeAdmin: "auth/isBackOfficeAdmin",
      loading: "isLoading",
    }),
    getGmtDiff() {
      return moment().utcOffset() / 60;
    },
    displayDate() {
      return (event) => event.datetime || event.created;
    },
    displayType() {
      return (event) => event.code || event.type
    },
    displayDescription() {
      return (event) => event.message || event.description
    }
  },
  filters: {
    getOnlyDate(datetime) {
      return moment.utc(datetime).local().format("YYYY-MM-DD");
    },
    getOnlyTime(datetime) {
      return moment.utc(datetime).local().format("HH:mm");
    },
  },

  methods: {
    closeDialog() {
      this.$emit("close");
    },
    copyToClipboard,
    async updateEvent() {
      this.$store.dispatch("loading", true);
      this.$refs.upEventObserver.validate().then(async (res) => {
        if (!res) return;
        try {
          Trainplanet.updateInquiryEvent(this.inquiryId, this.editItem.id, {
            ...this.editItem,
          }).then(async (res) => {
            await this.getInquiry();
            this.onSuccess(`Event updated successfully.`);
          });
          this.editItem = null;
        } catch (error) {
          await this.$store.dispatch(
            "error",
            error.response.data.message || error
          );
        } finally {
          await this.$store.dispatch("loading", false);
        }
      });
    },
    async deleteInquiry(eventId) {
      this.$confirm("Are you sure you want to delete this message?", {
        color: "primary",
        title: "Delete Inquiry Event",
      }).then(async (res) => {
        if (!res) return;

        try {
          await this.$store.dispatch("loading", true);
          await Trainplanet.deleteInquiryEvent(this.inquiryId, eventId);
          await this.getInquiry();
          this.onSuccess(`Event deleted successfully.`);
          this.editItem = null;
        } catch (error) {
          await this.$store.dispatch(
            "error",
            error.response.data.message || error
          );
        } finally {
          await this.$store.dispatch("loading", false);
        }
      });
    },
  },
};
</script>
