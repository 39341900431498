<template>
  <div id="inquiry-edit" v-if="Object.keys(inquiry).length">
    <inquiryInfoCard :inquiry="inquiry"></inquiryInfoCard>
    <v-row class="mt-0">
      <v-col cols="12" lg="6">
        <header
          class="theme--light v-navigation-drawer elevation-1"
          style="height: 50px; border-radius: 5px; margin-bottom: 10px"
        >
          <div
            class="v-toolbar__content"
            style="height: 50px; padding: 25px"
          >
            <div class="v-toolbar__title">
              <h4 class="black--text">Description</h4>
            </div>
          </div>
        </header>
        <v-card>
          <v-card-text
            v-html="inquiry.description"
            class="text--primary text-body-1"
            style="min-height: 272px"
          >
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" lg="6">
        <ClaimCustomer
          :customer="inquiry.customer"
          :inquiry-id="inquiryId"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="12">
        <header
          class="theme--light v-navigation-drawer elevation-1"
          style="height: 50px; border-radius: 5px; margin-bottom: 10px"
        >
          <div
            class="v-toolbar__content"
            style="height: 50px; padding: 25px"
          >
            <div class="v-toolbar__title">
              <h4 class="black--text">Activity Log</h4>
            </div>
          </div>
        </header>
        <v-card>
          <v-card-text>
            <v-timeline dense align-top>
              <v-timeline-item
                class="mb-12"
                color="secondary"
                icon="mdi-account"
                fill-dot
                large
              >
                <label for="select">Status</label>
                <ValidationObserver
                  ref="eventObserver"
                  v-slot="{ Validate, reset }"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="code"
                    rules="required"
                  >
                    <select
                      id="select"
                      v-model="event.code"
                      :class="{ 'my-select-dark': $vuetify.theme.dark }"
                      :error-messages="errors"
                      class="pl-2 py-1 mb-3 my-select"
                    >
                      <optgroup
                        v-for="(item, i) in eventStatus"
                        :label="item.text"
                        :key="i"
                      >
                        <option
                          v-for="(opt, index) in item.children"
                          :key="index"
                          :value="opt.code"
                        >
                          {{ opt.text }}
                        </option>
                      </optgroup>
                    </select>
                  </ValidationProvider>

                  <ValidationProvider
                    v-if="event.code.includes('refund')"
                    v-slot="{ errors }"
                    name="refundAmount"
                    rules="required|numeric"
                  >
                    <v-text-field
                      :error-messages="errors"
                      type="number"
                      v-model.number="event.refundAmount"
                      label="Amount of refund"
                      filled
                    >
                    </v-text-field>
                  </ValidationProvider>

                  <v-text-field
                    v-if="event.code.includes('refund')"
                    v-model="event.referencedBookingNumber"
                    label="Referenced Booking Number (special credit)"
                    filled
                    type="String"
                  >
                  </v-text-field>

                  <ValidationProvider v-slot="{ errors }" name="message">
                    <v-textarea
                      :error-messages="errors"
                      v-model="event.message"
                      label="Leave a comment..."
                      filled
                    >
                    </v-textarea>
                  </ValidationProvider>
                </ValidationObserver>

                <v-btn
                  @click="createEvent()"
                  class="mt-2"
                  :loading="event.loading"
                  color="accent"
                  large
                >Post
                </v-btn>
              </v-timeline-item>

              <event-list
                :eventStatus="eventStatus"
                :events="inquiry.events"
                :getInquiry="init"
                :inquiryId="inquiryId"
              />
            </v-timeline>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <float-buttons
      :inquiry="inquiry"
      @addEvent="inquiry.events.unshift($event)"
    />

  </div>
</template>

<script>
import Trainplanet from "@/util/trainplanet.api";
import moment from "moment";

import {ValidationObserver, ValidationProvider} from "vee-validate";
import ClaimCustomer from "../components/ClaimCustomer.vue";
import EventList from "../components/EventList";
import inquiryInfoCard from "@/components/info/inquiryInfoCard";
import FloatButtons from "./partials/FloatButtons";
import common from "../../../mixins/common";
import {getLegacySupportedBookingsFromInquiry} from "@/util/helpers";
import {eventStatus} from "../../../globals.js";

export default {
  name: "Edit",
  mixins: [common],
  props: {
    inquiryId: String,
  },
  components: {

    inquiryInfoCard,
    FloatButtons,
    ValidationProvider,
    ValidationObserver,
    ClaimCustomer,
    EventList,
  },
  data: () => ({
    customToolbar: [
      ["bold", "italic", "underline"],
      [{list: "ordered"}, {list: "bullet"}],
      [
        {align: ""},
        {align: "center"},
        {align: "right"},
        {align: "justify"},
      ],
      [{color: []}],
    ],
    inquiry: {},
    activeTab: null,
    invoice: null,
    zendeskId: null,
    zendeskIdModal: null,
    origin: null,
    event: {
      refundAmount: null,
      referencedBookingNumber: "",
      loading: false,
      message: "",
      code: "",
    },
    eventStatus: eventStatus,
  }),

  filters: {
    humanDatetime(datetime) {
      return moment.utc(datetime).local().fromNow();
    },
    getOnlyDate(datetime) {
      return moment.utc(datetime).format("YYYY-MM-DD");
    },
    getOnlyTime(datetime) {
      return moment.utc(datetime).local().format("HH:mm");
    },
  },
  computed: {
    getGmtDiff() {
      return moment().utcOffset() / 60;
    },
  },
  async mounted() {
    await this.init();
  },

  methods: {
    navInquiries(value) {
      window.open("/orders/inquiries/" + value);
    },
    async updateMessage() {
      this.$confirm(
        "You are about to change internal & external message now, do you want to continue?",
        {
          color: "primary",
          title: "Update Information",
        }
      )
        .then(async (res) => {
          if (!res) return;
          this.loading = true;
          let id = this.orderId;
          await Trainplanet.updateOrder(id, {
            internalMessage: this.order.internalMessage,
            externalMessage: this.order.externalMessage,
          })
            .then((res) => {
              this.$store.dispatch("success", "Messages Updated");
            })
            .catch((error) => {
              console.error(error);
              if (error.message) {
                this.$store.dispatch("error", error.message);
              }
            })
            .finally((r) => {
              this.loading = false;
            });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async init() {
      await this.$store.dispatch("loading", true);
      this.inquiry = await this.getInquiry();
      this.zendeskId = this.inquiry.zendeskId;

      let firstEvent = this.inquiry.events[0];
      if (firstEvent) {
        this.origin = firstEvent.user.fullName ?? firstEvent.user.name;
      }


      await this.$store.dispatch("loading", false);
    },
    async getInquiry() {
      return await Trainplanet.getInquiry(this.inquiryId);
    },
    getStatusText(code) {
      const find = (array, text) => {
        let result;
        array.some(
          (o) => (result = o.text === text ? o : find(o.children || [], text))
        );
        return result;
      };
      let result_;
      try {
        result_ = find(this.eventStatus, code).code;
      } catch (error) {
        result_ = code;
      }
      result_ = result_.replaceAll("_", " ").toLowerCase();
      const arr = result_?.split(" ");
      for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
      }
      result_ = arr.join(" ");
      return result_;
    },
    async createEvent() {
      this.$refs.eventObserver.validate().then((res) => {
        if (!res) return;
        this.$store.dispatch("loading", true);

        Trainplanet.createInquiryEvent(
          this.inquiryId,
          Object.assign({}, this.event)
        )
          .then(async (res) => {
            if (Number(this.inquiry.status) === 1) {
              window.location.reload();
            }

            this.inquiry.events.unshift(res);
            await this.$store.dispatch("success", "comment created");
            this.event.message = "";
            this.event.referencedBookingNumber = "";
            this.event.refundAmount = null;
          })
          .catch((error) => {
            this.$store.dispatch("error", error.response.data.message || error.message || error);
          })
          .finally((r) => {
            this.$store.dispatch("loading", false);
          });
      });
    },
    saveZendeskID() {
      this.$refs.zendeskIdObserver.validate().then((res) => {
        if (!res) return;
        this.$store.dispatch("loading", true);
        Trainplanet.changeZendeskId(this.inquiryId, {
          zendeskId: this.zendeskId,
        })
          .then(({data}) => {
            this.inquiry.events.unshift(data);
            this.inquiry.zendeskId = this.zendeskId;
            this.$store.dispatch("success", "Zendesk ID Updated");
            this.zendeskIdModal = false;
          })
          .catch((error) => {
            console.error(error);
            this.$store.dispatch("error", error.message);
          })
          .finally((r) => {
            this.$store.dispatch("loading", false);
          });
      });
    },
  },
};
</script>

<style>
#inquiry-edit .v-tabs-bar,
#inquiry-edit .v-tabs-items {
  background-color: transparent;
  overflow: unset;
}

.my-select {
  height: 55px;
  border: 1px solid rgb(173, 173, 173);
  border-radius: 8px;
  width: 100%;
  cursor: pointer;
}

.my-select-dark {
  /*color: #fff;*/
  border-color: #fff;
}
</style>
