<template>
  <v-row class="mt-0" v-if="customer" :value="0">
    <v-col cols="12" lg="12" class="mt-0 pt-0">
      <header
        class="theme--light v-navigation-drawer elevation-1"
        style="height: 50px; border-radius: 5px; margin-bottom: 10px"
      >
        <div class="v-toolbar__content" style="height: 50px; padding: 25px">
          <div class="v-toolbar__title">
            <h4 class="black--text">Claims Owner Information</h4>
          </div>
        </div>
      </header>
      <ValidationObserver ref="updateCustomerObserver">
        <v-card class="v-sheet">
          <v-card-text class="text--primary text-body-1 pa-8">
            <v-row>
              <v-col cols="12" sm="6" class="pa-0 pr-2">
                <v-text-field label="Firstname" v-model="customer.firstName" outlined />
              </v-col>
              <v-col cols="12" sm="6" class="pa-0 pl-2">
                <v-text-field label="Lastname" v-model="customer.lastName" outlined />
              </v-col>
              <v-col cols="12" sm="6" class="pa-0 pr-2">
                <v-text-field label="Phone" v-model="customer.phone" outlined />
              </v-col>
              <v-col cols="12" sm="6" class="pa-0 pl-2">
                <ValidationProvider v-slot="{ errors }" name="email" rules="email">
                  <v-text-field
                    label="E-Mail"
                    :error-messages="errors"
                    v-model="customer.email"
                    outlined
                    required
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="12" sm="6" class="pa-0 pl-2">
                <ValidationProvider v-slot="{ errors }">
                  <v-text-field
                    label="Account Number"
                    :error-messages="errors"
                    v-model="customer.payment.accountNumber"
                    outlined
                    required
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="12" sm="6" class="pa-0 pl-2">
                <ValidationProvider v-slot="{ errors }">
                  <v-text-field
                    label="Bank Name"
                    :error-messages="errors"
                    v-model="customer.payment.bankName"
                    outlined
                    required
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="12" sm="6" class="pa-0 pl-2">
                <ValidationProvider v-slot="{ errors }">
                  <v-text-field
                    label="Clearing Number"
                    :error-messages="errors"
                    v-model="customer.payment.clearingNumber"
                    outlined
                    required
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="12">
                <v-btn class="accent" @click="update()" :loading="loading">Update</v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </ValidationObserver>
    </v-col>
  </v-row>
</template>

<script>
import Trainplanet from "@/util/trainplanet.api";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  name: "ClaimCustomer",
  components: {
    ValidationProvider,
    ValidationObserver
  },
  props: {
    customer: Object,
    inquiryId: {
      required: false,
      default: false
    }
  },
  data: () => ({
    loading: false
  }),
  methods: {
    async update() {
      this.$refs.updateCustomerObserver.validate().then(async res => {
        if (!res) return;
        this.loading = true;
        let id = this.inquiryId;
        await Trainplanet.updateInquiryCustomerInfo(id, this.customer)
          .then(res => {
            this.$store.dispatch("success", "Customer information Updated");
          })
          .catch(error => {
            if (error.message) {
              this.$store.dispatch("error", error.message);
            }
          })
          .finally(r => {
            this.loading = false;
          });
      });
    }
  }
};
</script>

<style></style>
