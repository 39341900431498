<template>
  <div v-if="order">
    <div class="elevation-1 rounded pa-5 white "
         :class="inquiry.status == 0 ? 'greeny--border' : 'primary--border'">
      <v-row class="align-center">
        <v-col md="auto">
          <h3>
            <span class="primary--text">Status :  </span>
            {{ getStatusText(lastEvent(inquiry.events[0]).code) }}
          </h3>
          <div class="text-caption mt-1 font-weight-bold">
            Inquiry Created
            <code class="text-caption"
            >{{ inquiry.created | getOnlyDate }}
              {{ inquiry.created | getOnlyTime }}</code
            >
            (GMT+{{ getGmtDiff }}) by
            <code class="text-caption greeny white--text">{{ origin }}</code>
          </div>
        </v-col>
        <v-spacer></v-spacer>
        <v-col md="auto">
          <div class="d-flex align-center justify-end">
            <h3 class="secondary--text">
              {{ order.id }}
            </h3>
            <OrderIconsField
              :order="order"
              :showPrintableInfo="true"
              :showDistributedInfo="true"
              :showGroupOrderInfo="true"
              :showClaimInfo="false"
              :showArchiveOrder="true"
            ></OrderIconsField>
            <v-btn @click="navOrder(order.id)" class="ml-2" small color="accent"
            >Order Detail
            </v-btn>
          </div>

          <div class="caption secondary--text mt-1 font-weight-bold">
            Order Created
            <code class="text-caption"
            >{{ order.created | getOnlyDate }}
              {{ order.created | getOnlyTime }}</code
            >
            (GMT+{{ getGmtDiff }}) by
            <code class="text-caption greeny white--text ">
              {{ order.seller.user.fullName ?? order.seller.user.name }}</code>
          </div>
        </v-col>
      </v-row>
    </div>

    <v-toolbar class="elevation-1 rounded mt-2 white">
      <div class="d-flex">
        <span class="font-weight-bold secondary--text"
        ><img
          width="20px"
          class="mr-1"
          src="@/assets/linkonline.svg"
        />Linkonline :
          <span v-if="order.bookings.length < 1" class="grey--text">
            No Linkonline Booking</span
          >
        </span>
        <div
          v-for="booking in groupedByExternalSystem(order.bookings)"
          :key="booking.id"
          class="d-flex align-center ml-2"
        >
          <span
            class="font-weight-black secondary--text"
            v-for="(id, _) in booking.groupedExternalIds"
            :key="id"
          >{{ id }}
            <v-tooltip
              top
              :open-delay="1000"
              :close-delay="500"
              :open-on-hover="false"
            >
              <template v-slot:activator="{ on }">
                <v-icon
                  color="mango-red"
                  @click="copyToClipboard(id)"
                  small
                  v-on="on"
                >
                  mdi-content-copy
                </v-icon>
              </template>
              <span>Copied</span>
            </v-tooltip>
            <span
              v-if="booking.groupedExternalIds.length - 1 !== _"
              class="black--text mx-1"
            >
              /
            </span>
          </span>
        </div>
      </div>
      <v-spacer></v-spacer>
      <v-toolbar-title class="font-weight-bold black--text d-flex align-center">
        <img
          width="26px"
          class="mr-2"
          :src="
            'https://assets.trainplanet.com/wlabel/' +
            fixUrl(order.seller.tenant.name) +
            '/logos/favicon-32x32.png'
          "
        />
        {{ order.seller.tenant.name }}
      </v-toolbar-title>
    </v-toolbar>
  </div>
</template>

<script>
import moment from "moment";
import OrderIDField from "@/components/common/OrderIDField";
import {copyToClipboard, fixUrl} from "@/util/helpers";
import toolbarTitle from "@/components/common/toolbarTitle";
import OrderIconsField from "@/components/common/OrderIconsField";
import common from "@/mixins/common";

export default {
  components: {
    OrderIconsField,
    toolbarTitle,
    copyToClipboard,
    OrderIDField,
  },
  computed: {
    getGmtDiff() {
      return moment().utcOffset() / 60;
    },
  },
  mixins: [common],
  data: () => ({
    order: null,
    origin: null,
  }),
  filters: {
    humanDatetime(datetime) {
      return moment.utc(datetime).local().fromNow();
    },
    getOnlyDate(datetime) {
      return moment.utc(datetime).format("YYYY-MM-DD");
    },
    getOnlyTime(datetime) {
      return moment.utc(datetime).local().format("HH:mm");
    },
  },
  mounted() {
    this.order = this.inquiry.orders[0];
    let firstEvent = this.inquiry.events[0];
    if (firstEvent) {
      this.origin = firstEvent.user.fullName ?? firstEvent.user.name;
    }
  },
  methods: {
    copyToClipboard,
    fixUrl,
    navOrder(value) {
      window.open("/orders/" + value);
    },
    groupedByExternalSystem(items = []) {
      return items.reduce((acc, curr) => {
        const externalSystem = acc.find(
          (item) => curr.externalSystem === item.externalSystem
        );
        if (externalSystem) {
          externalSystem.groupedExternalIds.push(curr.externalOrderId);
        } else {
          acc.push({...curr, groupedExternalIds: [curr.externalOrderId]});
        }
        return acc;
      }, []);
    },

    lastEvent(event) {
      return {
        message: event ? event.message : null,
        user: event ? (event.user.fullName ?? event.user.name) : null,
        code: event ? event.code : null,
      };
    },

    getStatusText(code) {
      const find = (array, text) => {
        let result;
        array.some(
          (o) => (result = o.text === text ? o : find(o.children || [], text))
        );
        return result;
      };
      let result_;
      try {
        result_ = find(this.eventStatus, code).code;
      } catch (error) {
        result_ = code;
      }
      result_ = result_.replaceAll("_", " ").toLowerCase();
      const arr = result_?.split(" ");
      for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
      }
      result_ = arr.join(" ");
      return result_;
    },
  },
  props: {
    inquiry: [],
  },
};
</script>

<style lang="scss" scoped>

.greeny--border {
  border-bottom: 5px solid #86c589 !important;
}

.primary--border {
  border-bottom: 5px solid #f37a62 !important;
}
</style>
