var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-speed-dial', {
    attrs: {
      "direction": "left",
      "bottom": "",
      "right": "",
      "fixed": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function () {
        return [_c('v-btn', {
          attrs: {
            "large": "",
            "color": "accent",
            "fab": ""
          },
          model: {
            value: _vm.fab,
            callback: function ($$v) {
              _vm.fab = $$v;
            },
            expression: "fab"
          }
        }, [_vm.fab ? _c('v-icon', [_vm._v("mdi-close")]) : _c('v-icon', [_vm._v("mdi-file-document-edit")])], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.fab,
      callback: function ($$v) {
        _vm.fab = $$v;
      },
      expression: "fab"
    }
  }, [_c('v-tooltip', {
    attrs: {
      "top": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [Number(_vm.inquiry.status) !== 1 ? _c('v-btn', _vm._g(_vm._b({
          attrs: {
            "fab": "",
            "dark": "",
            "color": "third"
          },
          on: {
            "click": _vm.updateLock
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-lock-open-outline")])], 1) : _c('v-btn', _vm._g(_vm._b({
          attrs: {
            "fab": "",
            "dark": "",
            "color": "third"
          },
          on: {
            "click": _vm.updateLock
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-lock-open-variant-outline")])], 1)];
      }
    }])
  }, [Number(_vm.inquiry.status) !== 1 ? _c('span', [_vm._v("Close Ticket")]) : _c('span', [_vm._v("ReOpen Ticket")])])], 1), _c('v-dialog', {
    attrs: {
      "max-width": "700px"
    },
    model: {
      value: _vm.closeTicketModal,
      callback: function ($$v) {
        _vm.closeTicketModal = $$v;
      },
      expression: "closeTicketModal"
    }
  }, [_c('v-container', [_c('v-card', {
    attrs: {
      "disabled": _vm.$store.state.loading
    }
  }, [_c('v-flex', {
    staticClass: "mb-12"
  }), _c('base-material-card', {
    attrs: {
      "color": "warning",
      "icon": "mdi-lock-open-alert"
    },
    scopedSlots: _vm._u([{
      key: "after-heading",
      fn: function () {
        return [_c('div', {
          staticClass: "ml-auto"
        }, [_c('h3', [_vm.isClosed ? _c('span', [_vm._v("Open")]) : _c('span', [_vm._v("Close")]), _vm._v(" Ticket ")])])];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c('v-btn', {
          attrs: {
            "color": "red",
            "outlined": "",
            "x-large": ""
          },
          on: {
            "click": function ($event) {
              _vm.closeTicketModal = false;
            }
          }
        }, [_vm._v("Not Now")]), _c('v-spacer'), _c('v-btn', {
          attrs: {
            "loading": _vm.$store.state.loading,
            "color": "success",
            "x-large": ""
          },
          on: {
            "click": _vm.updatelock
          }
        }, [_vm.isClosed ? _c('span', [_vm._v("Open Ticket")]) : _c('span', [_vm._v("Close Ticket")])])];
      },
      proxy: true
    }])
  }, [_c('v-divider', {
    staticClass: "mt-2"
  }), _c('v-card-text', [_c('ValidationObserver', {
    ref: "ticketObserver",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref2) {
        var Validate = _ref2.Validate,
          reset = _ref2.reset;
        return [_c('ValidationProvider', {
          attrs: {
            "name": "description",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref3) {
              var errors = _ref3.errors;
              return [_c('v-textarea', {
                attrs: {
                  "filled": "",
                  "label": "Description",
                  "error-messages": errors,
                  "clearable": ""
                },
                model: {
                  value: _vm.ticket.description,
                  callback: function ($$v) {
                    _vm.$set(_vm.ticket, "description", $$v);
                  },
                  expression: "ticket.description"
                }
              })];
            }
          }], null, true)
        })];
      }
    }])
  })], 1)], 1)], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "max-width": "700px"
    },
    model: {
      value: _vm.inquiryModal,
      callback: function ($$v) {
        _vm.inquiryModal = $$v;
      },
      expression: "inquiryModal"
    }
  }, [_c('v-container', [_c('v-card', {
    attrs: {
      "disabled": _vm.$store.state.loading
    }
  }, [_c('v-flex', {
    staticClass: "mb-12"
  }), _c('base-material-card', {
    attrs: {
      "color": "warning",
      "icon": "mdi-pencil"
    },
    scopedSlots: _vm._u([{
      key: "after-heading",
      fn: function () {
        return [_c('div', {
          staticClass: "ml-auto"
        }, [_c('div', {
          staticClass: "body-3 grey--text font-weight-light",
          domProps: {
            "textContent": _vm._s('Add Orders')
          }
        }), _c('h3', [_vm._v("Add Orders to claim")])])];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c('v-btn', {
          attrs: {
            "color": "red",
            "outlined": "",
            "x-large": ""
          },
          on: {
            "click": function ($event) {
              _vm.inquiryModal = false;
            }
          }
        }, [_vm._v("Not Now")]), _c('v-spacer'), _c('v-btn', {
          attrs: {
            "loading": _vm.$store.state.loading,
            "color": "success",
            "x-large": ""
          },
          on: {
            "click": _vm.createInquiry
          }
        }, [_vm._v(" Add new orders ")])];
      },
      proxy: true
    }])
  }, [_c('v-divider', {
    staticClass: "mt-2"
  }), _c('v-card-text', [_c('ValidationObserver', {
    ref: "inquiryObserver",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref4) {
        var Validate = _ref4.Validate,
          reset = _ref4.reset;
        return [_c('ValidationProvider', {
          attrs: {
            "name": "orders",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref5) {
              var errors = _ref5.errors;
              return [_c('v-combobox', {
                staticClass: "mb-2",
                attrs: {
                  "error-messages": errors,
                  "filled": "",
                  "hint": "Add Order Id then press Enter",
                  "label": "Add Order Ids",
                  "multiple": "",
                  "chips": "",
                  "clearable": ""
                },
                model: {
                  value: _vm.newItem.orders,
                  callback: function ($$v) {
                    _vm.$set(_vm.newItem, "orders", $$v);
                  },
                  expression: "newItem.orders"
                }
              })];
            }
          }], null, true)
        })];
      }
    }])
  }), _c('ValidationObserver', {
    ref: "inquiryObserver",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref6) {
        var Validate = _ref6.Validate,
          reset = _ref6.reset;
        return [_c('ValidationProvider', {
          attrs: {
            "name": "description",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref7) {
              var errors = _ref7.errors;
              return [_c('v-textarea', {
                attrs: {
                  "filled": "",
                  "label": "Description",
                  "error-messages": errors,
                  "clearable": ""
                },
                model: {
                  value: _vm.newItem.description,
                  callback: function ($$v) {
                    _vm.$set(_vm.newItem, "description", $$v);
                  },
                  expression: "newItem.description"
                }
              })];
            }
          }], null, true)
        })];
      }
    }])
  })], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }