<template>
  <div>
    <v-speed-dial v-model="fab" direction="left" bottom right fixed>
      <template v-slot:activator>
        <v-btn v-model="fab" large color="accent"  fab>
          <v-icon v-if="fab">mdi-close</v-icon>
          <v-icon v-else>mdi-file-document-edit</v-icon>
        </v-btn>
      </template>

      <!-- <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="inquiryModal = true" fab dark color="greeny" v-bind="attrs" v-on="on">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>

        <span>Add order to this claim</span>
      </v-tooltip> -->

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-if="Number(inquiry.status) !== 1"
            @click="updateLock"
            fab
            dark
            color="third"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-lock-open-outline</v-icon>
          </v-btn>

          <v-btn v-else @click="updateLock" fab dark color="third" v-bind="attrs" v-on="on">
            <v-icon>mdi-lock-open-variant-outline</v-icon>
          </v-btn>
        </template>
        <span v-if="Number(inquiry.status) !== 1">Close Ticket</span>
        <span v-else>ReOpen Ticket</span>
      </v-tooltip>
    </v-speed-dial>
    <!-- open / close ticket -->
    <v-dialog v-model="closeTicketModal" max-width="700px">
      <v-container>
        <v-card :disabled="$store.state.loading">
          <v-flex class="mb-12"></v-flex>
          <base-material-card color="warning" icon="mdi-lock-open-alert">
            <template v-slot:after-heading>
              <div class="ml-auto">
                <h3>
                  <span v-if="isClosed">Open</span>
                  <span v-else>Close</span> Ticket
                </h3>
              </div>
            </template>
            <v-divider class="mt-2" />

            <v-card-text>
              <!-- Description -->
              <ValidationObserver ref="ticketObserver" v-slot="{ Validate, reset }">
                <ValidationProvider v-slot="{ errors }" name="description" rules="required">
                  <v-textarea
                    filled
                    label="Description"
                    v-model="ticket.description"
                    :error-messages="errors"
                    clearable
                  />
                </ValidationProvider>
              </ValidationObserver>
            </v-card-text>
            <template v-slot:actions>
              <v-btn color="red" outlined @click="closeTicketModal = false" x-large>Not Now</v-btn>
              <v-spacer></v-spacer>
              <v-btn :loading="$store.state.loading" @click="updatelock" color="success" x-large>
                <span v-if="isClosed">Open Ticket</span>
                <span v-else>Close Ticket</span>
              </v-btn>
            </template>
          </base-material-card>
        </v-card>
      </v-container>
    </v-dialog>

    <v-dialog v-model="inquiryModal" max-width="700px">
      <v-container>
        <v-card :disabled="$store.state.loading">
          <v-flex class="mb-12"></v-flex>
          <base-material-card color="warning" icon="mdi-pencil">
            <template v-slot:after-heading>
              <div class="ml-auto">
                <div class="body-3 grey--text font-weight-light" v-text="'Add Orders'" />
                <h3>Add Orders to claim</h3>
              </div>
            </template>
            <v-divider class="mt-2" />

            <v-card-text>
              <!-- Orders -->
              <ValidationObserver ref="inquiryObserver" v-slot="{ Validate, reset }">
                <ValidationProvider v-slot="{ errors }" name="orders" rules="required">
                  <v-combobox
                    v-model="newItem.orders"
                    :error-messages="errors"
                    filled
                    class="mb-2"
                    hint="Add Order Id then press Enter"
                    label="Add Order Ids"
                    multiple
                    chips
                    clearable
                  ></v-combobox>
                </ValidationProvider>
              </ValidationObserver>

              <!-- Description -->
              <ValidationObserver ref="inquiryObserver" v-slot="{ Validate, reset }">
                <ValidationProvider v-slot="{ errors }" name="description" rules="required">
                  <v-textarea
                    filled
                    label="Description"
                    v-model="newItem.description"
                    :error-messages="errors"
                    clearable
                  />
                </ValidationProvider>
              </ValidationObserver>
            </v-card-text>
            <template v-slot:actions>
              <v-btn color="red" outlined @click="inquiryModal = false" x-large>Not Now</v-btn>
              <v-spacer></v-spacer>
              <v-btn :loading="$store.state.loading" @click="createInquiry" color="success" x-large>
                Add new orders
              </v-btn>
            </template>
          </base-material-card>
        </v-card>
      </v-container>
    </v-dialog>
  </div>
</template>

<script>
import Trainplanet from "@/util/trainplanet.api";
import {ValidationObserver, ValidationProvider} from "vee-validate";

export default {
  name: "FloatButtons",
  components: {
    ValidationProvider,
    ValidationObserver
  },
  props: {
    inquiry: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      inquiryModal: false,
      isClosed: false,
      closeTicketModal: false,
      newItem: {},
      ticket: {},
      fab: false
    };
  },
  methods: {
    createInquiry() {
      this.$refs.inquiryObserver.validate().then(res => {
        if (!res) return;
        this.$store.dispatch("loading", true);
        Trainplanet.updateInquiry(this.inquiry.id, this.newItem)
          .catch(error => {
            let message = error.message;
            if (error.response) {
              message = error.response.data.message;
            }
            this.$store.dispatch("error", message);
          })
          .finally(() => {
            this.$store.dispatch("loading", false);
          });
      });
    },
    updateLock(status) {
      this.$confirm("Are you sure?", {
        color: "primary",
        title: !this.inquiry.status ? "Close Ticket" : "Re-Open Ticket"
      }).then(res => {
        if (!res) return;
        Trainplanet.changeStatusInquiry(this.inquiry.id, { description: this.ticket.description })
          .then(res => {
            this.$emit("addEvent", res.data);
            this.$store.dispatch("success", "Claim updated.");
            this.closeTicketModal = false;
            this.inquiry.status = !this.inquiry.status;
          })
          .catch(error => {
            let message = error.message;
            if (error.response) {
              console.error(error.response);
              message = error.response.data.message;
            }
            this.$store.dispatch("error", message);
          })
          .finally(() => {
            this.$store.dispatch("loading", false);
          });
      });

      // this.$refs.ticketObserver.validate().then(res => {
      //   if (!res) return;
      //   this.$store.dispatch("loading", true);
      //   Trainplanet.changeStatusInquiry(this.inquiry.id, { description: this.ticket.description })
      //     .then(res => {
      //       this.$emit("addEvent", res.data);
      //       this.$store.dispatch("success", "Claim updated.");
      //       this.closeTicketModal = false;
      //       this.inquiry.status = !this.inquiry.status;
      //       this.ticket.description = null;
      //     })
      //     .catch(error => {
      //       let message = error.message;
      //       if (error.response) {
      //         console.error(error.response);
      //         message = error.response.data.message;
      //       }
      //       this.$store.dispatch("error", message);
      //     })
      //     .finally(() => {
      //       this.$store.dispatch("loading", false);
      //     });
      // });
    }
  },
  created() {
    this.newItem.description = this.inquiry.description;
  }
};
</script>

<style></style>
