var render = function render(){
  var _vm$order$seller$user;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.order ? _c('div', [_c('div', {
    staticClass: "elevation-1 rounded pa-5 white",
    class: _vm.inquiry.status == 0 ? 'greeny--border' : 'primary--border'
  }, [_c('v-row', {
    staticClass: "align-center"
  }, [_c('v-col', {
    attrs: {
      "md": "auto"
    }
  }, [_c('h3', [_c('span', {
    staticClass: "primary--text"
  }, [_vm._v("Status : ")]), _vm._v(" " + _vm._s(_vm.getStatusText(_vm.lastEvent(_vm.inquiry.events[0]).code)) + " ")]), _c('div', {
    staticClass: "text-caption mt-1 font-weight-bold"
  }, [_vm._v(" Inquiry Created "), _c('code', {
    staticClass: "text-caption"
  }, [_vm._v(_vm._s(_vm._f("getOnlyDate")(_vm.inquiry.created)) + " " + _vm._s(_vm._f("getOnlyTime")(_vm.inquiry.created)))]), _vm._v(" (GMT+" + _vm._s(_vm.getGmtDiff) + ") by "), _c('code', {
    staticClass: "text-caption greeny white--text"
  }, [_vm._v(_vm._s(_vm.origin))])])]), _c('v-spacer'), _c('v-col', {
    attrs: {
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center justify-end"
  }, [_c('h3', {
    staticClass: "secondary--text"
  }, [_vm._v(" " + _vm._s(_vm.order.id) + " ")]), _c('OrderIconsField', {
    attrs: {
      "order": _vm.order,
      "showPrintableInfo": true,
      "showDistributedInfo": true,
      "showGroupOrderInfo": true,
      "showClaimInfo": false,
      "showArchiveOrder": true
    }
  }), _c('v-btn', {
    staticClass: "ml-2",
    attrs: {
      "small": "",
      "color": "accent"
    },
    on: {
      "click": function ($event) {
        return _vm.navOrder(_vm.order.id);
      }
    }
  }, [_vm._v("Order Detail ")])], 1), _c('div', {
    staticClass: "caption secondary--text mt-1 font-weight-bold"
  }, [_vm._v(" Order Created "), _c('code', {
    staticClass: "text-caption"
  }, [_vm._v(_vm._s(_vm._f("getOnlyDate")(_vm.order.created)) + " " + _vm._s(_vm._f("getOnlyTime")(_vm.order.created)))]), _vm._v(" (GMT+" + _vm._s(_vm.getGmtDiff) + ") by "), _c('code', {
    staticClass: "text-caption greeny white--text"
  }, [_vm._v(" " + _vm._s((_vm$order$seller$user = _vm.order.seller.user.fullName) !== null && _vm$order$seller$user !== void 0 ? _vm$order$seller$user : _vm.order.seller.user.name))])])])], 1)], 1), _c('v-toolbar', {
    staticClass: "elevation-1 rounded mt-2 white"
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('span', {
    staticClass: "font-weight-bold secondary--text"
  }, [_c('img', {
    staticClass: "mr-1",
    attrs: {
      "width": "20px",
      "src": require("@/assets/linkonline.svg")
    }
  }), _vm._v("Linkonline : "), _vm.order.bookings.length < 1 ? _c('span', {
    staticClass: "grey--text"
  }, [_vm._v(" No Linkonline Booking")]) : _vm._e()]), _vm._l(_vm.groupedByExternalSystem(_vm.order.bookings), function (booking) {
    return _c('div', {
      key: booking.id,
      staticClass: "d-flex align-center ml-2"
    }, _vm._l(booking.groupedExternalIds, function (id, _) {
      return _c('span', {
        key: id,
        staticClass: "font-weight-black secondary--text"
      }, [_vm._v(_vm._s(id) + " "), _c('v-tooltip', {
        attrs: {
          "top": "",
          "open-delay": 1000,
          "close-delay": 500,
          "open-on-hover": false
        },
        scopedSlots: _vm._u([{
          key: "activator",
          fn: function (_ref) {
            var on = _ref.on;
            return [_c('v-icon', _vm._g({
              attrs: {
                "color": "mango-red",
                "small": ""
              },
              on: {
                "click": function ($event) {
                  return _vm.copyToClipboard(id);
                }
              }
            }, on), [_vm._v(" mdi-content-copy ")])];
          }
        }], null, true)
      }, [_c('span', [_vm._v("Copied")])]), booking.groupedExternalIds.length - 1 !== _ ? _c('span', {
        staticClass: "black--text mx-1"
      }, [_vm._v(" / ")]) : _vm._e()], 1);
    }), 0);
  })], 2), _c('v-spacer'), _c('v-toolbar-title', {
    staticClass: "font-weight-bold black--text d-flex align-center"
  }, [_c('img', {
    staticClass: "mr-2",
    attrs: {
      "width": "26px",
      "src": 'https://assets.trainplanet.com/wlabel/' + _vm.fixUrl(_vm.order.seller.tenant.name) + '/logos/favicon-32x32.png'
    }
  }), _vm._v(" " + _vm._s(_vm.order.seller.tenant.name) + " ")])], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }