var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.customer ? _c('v-row', {
    staticClass: "mt-0",
    attrs: {
      "value": 0
    }
  }, [_c('v-col', {
    staticClass: "mt-0 pt-0",
    attrs: {
      "cols": "12",
      "lg": "12"
    }
  }, [_c('header', {
    staticClass: "theme--light v-navigation-drawer elevation-1",
    staticStyle: {
      "height": "50px",
      "border-radius": "5px",
      "margin-bottom": "10px"
    }
  }, [_c('div', {
    staticClass: "v-toolbar__content",
    staticStyle: {
      "height": "50px",
      "padding": "25px"
    }
  }, [_c('div', {
    staticClass: "v-toolbar__title"
  }, [_c('h4', {
    staticClass: "black--text"
  }, [_vm._v("Claims Owner Information")])])])]), _c('ValidationObserver', {
    ref: "updateCustomerObserver"
  }, [_c('v-card', {
    staticClass: "v-sheet"
  }, [_c('v-card-text', {
    staticClass: "text--primary text-body-1 pa-8"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "pa-0 pr-2",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Firstname",
      "outlined": ""
    },
    model: {
      value: _vm.customer.firstName,
      callback: function ($$v) {
        _vm.$set(_vm.customer, "firstName", $$v);
      },
      expression: "customer.firstName"
    }
  })], 1), _c('v-col', {
    staticClass: "pa-0 pl-2",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Lastname",
      "outlined": ""
    },
    model: {
      value: _vm.customer.lastName,
      callback: function ($$v) {
        _vm.$set(_vm.customer, "lastName", $$v);
      },
      expression: "customer.lastName"
    }
  })], 1), _c('v-col', {
    staticClass: "pa-0 pr-2",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Phone",
      "outlined": ""
    },
    model: {
      value: _vm.customer.phone,
      callback: function ($$v) {
        _vm.$set(_vm.customer, "phone", $$v);
      },
      expression: "customer.phone"
    }
  })], 1), _c('v-col', {
    staticClass: "pa-0 pl-2",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "email",
      "rules": "email"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var errors = _ref.errors;
        return [_c('v-text-field', {
          attrs: {
            "label": "E-Mail",
            "error-messages": errors,
            "outlined": "",
            "required": ""
          },
          model: {
            value: _vm.customer.email,
            callback: function ($$v) {
              _vm.$set(_vm.customer, "email", $$v);
            },
            expression: "customer.email"
          }
        })];
      }
    }], null, false, 489671299)
  })], 1), _c('v-col', {
    staticClass: "pa-0 pl-2",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('ValidationProvider', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref2) {
        var errors = _ref2.errors;
        return [_c('v-text-field', {
          attrs: {
            "label": "Account Number",
            "error-messages": errors,
            "outlined": "",
            "required": ""
          },
          model: {
            value: _vm.customer.payment.accountNumber,
            callback: function ($$v) {
              _vm.$set(_vm.customer.payment, "accountNumber", $$v);
            },
            expression: "customer.payment.accountNumber"
          }
        })];
      }
    }], null, false, 911213914)
  })], 1), _c('v-col', {
    staticClass: "pa-0 pl-2",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('ValidationProvider', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref3) {
        var errors = _ref3.errors;
        return [_c('v-text-field', {
          attrs: {
            "label": "Bank Name",
            "error-messages": errors,
            "outlined": "",
            "required": ""
          },
          model: {
            value: _vm.customer.payment.bankName,
            callback: function ($$v) {
              _vm.$set(_vm.customer.payment, "bankName", $$v);
            },
            expression: "customer.payment.bankName"
          }
        })];
      }
    }], null, false, 879022874)
  })], 1), _c('v-col', {
    staticClass: "pa-0 pl-2",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('ValidationProvider', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref4) {
        var errors = _ref4.errors;
        return [_c('v-text-field', {
          attrs: {
            "label": "Clearing Number",
            "error-messages": errors,
            "outlined": "",
            "required": ""
          },
          model: {
            value: _vm.customer.payment.clearingNumber,
            callback: function ($$v) {
              _vm.$set(_vm.customer.payment, "clearingNumber", $$v);
            },
            expression: "customer.payment.clearingNumber"
          }
        })];
      }
    }], null, false, 21848634)
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', {
    staticClass: "accent",
    attrs: {
      "loading": _vm.loading
    },
    on: {
      "click": function ($event) {
        return _vm.update();
      }
    }
  }, [_vm._v("Update")])], 1)], 1)], 1)], 1)], 1)], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }